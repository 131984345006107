.NftSelect {
    width: 500px;
    height: 525px;
    background: #f7f7f9;
    z-index: 1;
    border-radius: 20px;
    padding: 40px 25px 20px 25px;
    border: 1px solid white;
}
.nftSelectBox {
    position: relative;
    z-index: 1;
    background: #f1ecec4d;
    backdrop-filter: blur(42px);
    border-radius: 16px;
    border: 1px solid #ffffff;
    margin-bottom: 26px;
}
.aboutNft {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 12px;
    gap: 20px;
    padding-top: 22px;
}
.seleDepat:hover {
    background: #ececf1;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    cursor: pointer;
}
.seleDesti:hover {
    background: #ececf1;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    cursor: pointer;
}
.new-chain {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 5%;
    line-height: 15.12px;
    color: white;
    background: linear-gradient(100.8deg, #29ffcc -59.7%, #3763ff 123.09%);
    padding: 2px 8px;
    border-radius: 14px;
}
.updated {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 5%;
    line-height: 15.12px;
    color: white;
    background: linear-gradient(281.21deg, #10b67a 19.02%, #20bcde 99.09%);
    padding: 2px 8px;
    border-radius: 14px;
}
.chain__off {
    width: 50px;
    height: 20px;
    border-radius: 4px;
    font-size: 12px;
    color: #ed3e3e;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nftSlectArea {
    max-width: 380px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}
.coming-chain {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 5%;
    line-height: 15.12px;
    color: white;
    background: linear-gradient(283.28deg, #3763ff 0%, #8f37ff 111.38%);
    padding: 4px 6px;
    border-radius: 14px;
}
.maintenance-chain {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 5%;
    line-height: 15.12px;
    color: white;
    background: linear-gradient(273.19deg, #2fc3e0 3.07%, #a8efff 138.75%);

    padding: 4px 6px;
    border-radius: 14px;
}

.beacon img {
    width: 28px;
}

.algo img {
    width: 28px;
    border-radius: 5px;
}
.nftChainList {
    list-style: none;
    max-height: 364px;
    overflow: scroll;
}

.nftChainItem {
    display: flex;
    align-items: center;
    height: 56px;
    margin: 8px 26px;
    padding: 14px 16px;
    cursor: pointer;
    transition: all 0.5s;
    border-radius: 86px;
    border: 1px solid white;
    box-shadow: 0px 1px 6px rgba(180, 185, 199, 0.25);
}

.nftChainItem img {
    display: block;
    width: 28px;
    margin-right: 13px;
}

.nftChainItem:hover {
    background: rgba(73, 82, 108, 0.1);
}

.chain-connecting {
    display: flex;
    min-width: 102px;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.05em;
    background: #ececf1;
    border-radius: 14px;
    padding: 2px 8px;
}
.chain-select__box {
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
    color: #14161a;
    margin-bottom: 32px;
}
.connect-wallet__button {
    background-color: #395feb;
    color: white;
    width: 100%;
    height: 56px;
    border-radius: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    margin-bottom: 24px;
}
.connect-wallet__button:hover {
    background: #2d51d0;
}
.connect-wallet__button--disabled {
    border-radius: 62px;
    border: 1px solid #395feb;
    color: #395feb;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 20px;
    letter-spacing: 0.01em !important;
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    margin-bottom: 24px;
}
.chain-sep__line {
    height: 1px;
    background-color: rgb(255, 255, 255);
    display: block;
}
.swap-chain__btn {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2000;
    cursor: pointer;
    user-select: none;
}
.swap-chain__btn:hover:before {
    position: absolute;
    content: url("../../assets/img/icons/swapChain_hover.svg");
    z-index: 2001;
    user-select: none;
}
.selChain {
    padding: 22px 25px;
    position: relative;
    z-index: 1;
    color: #fff;
}
.selChain img {
    display: block;
    /* margin-right: 16px; */
}
.selChain > div {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
    gap: 35px;
}
.selChain > div:after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../../assets/img/icons/arrow_down.svg);
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 6px;
}
.selChain:hover > div:after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../../assets/img/icons/arrow_down_hover.svg);
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 6px;
}
span.chainArrow {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2000;
}
span.chainArrow:hover {
    cursor: pointer;
}
.selChain,
.seleDestiSele {
    color: #14161a;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    user-select: none;
}

@media screen and (max-width: 1024px) {
    .NftSelect {
        width: 390px;
    }
}

@media screen and (max-width: 600px) {
    .chain-select__box {
        font-size: 22px;
    }
}

@media screen and (max-width: 320px) {
    .NftSelect,
    nav {
        width: 288px;
    }

    .slider__wrapper {
        width: 288px !important;
    }
}

@media screen and (max-width: 375px) {
    .NftSelect {
        width: 344px;
    }
    .slider__wrapper {
        width: 344px !important;
    }
}
